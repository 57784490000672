/* ##### trust Area CSS ##### */
.HomeDemo2 .trust-section{
    position: relative;
    padding: 100px 0 60px
}
.HomeDemo2 .trust-item{
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 40px;
    padding: 0 35px;
    min-height: 205px;
    padding-top: 37px;
    padding-bottom: 25px;
    box-shadow: 4px 4px 10px 0 rgba(168,67,253,.3);
    overflow: hidden;
    border-bottom: 3px solid #a843fd;
}
.HomeDemo2 .ico-platform-logo{
    margin-bottom: 25px;
    min-height: 75px
}
.HomeDemo2 .check {
    height: 40px;
    margin: 0 -10px;
    background-color: rgba(13,0,59,.9);
    border-radius: 5px;
    color: #25cbd3;
    position: relative;
}
.HomeDemo2 .check .value {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 24px;
    font-weight: 600;
    text-shadow: 0 0 5px rgba(0,243,255,.5);
}
.HomeDemo2 .check .check-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: url(../../../assets/img/svg/checkmark.svg) 50% no-repeat;
    background-size: contain;
    width: 31px;
    height: 23px;
}

.HomeDemo2 .token-distribution{
    padding-bottom: 100px;
    overflow: hidden;
}

@media (max-width: 767px) {
    .HomeDemo2 .token-info-wapper{
        margin-top: 30px;
    }
}
.HomeDemo2 .token-info {
    padding: 0 12px;
    width: 100%;
    margin-bottom: 20px;
    float: left;
    display: -ms-flexbox;
    display: flex;
}
.HomeDemo2 .token-info .info-wrapper {
    border: 1px solid #25cbd3;
    border-radius: 10px;
    padding: 19px 36px;
    padding-left: 65px;
    background-color: #0d003b;
    width: 100%;
    position: relative;
    -ms-flex: 1;
    flex: 1;
    display: -ms-flexbox;
    display: flex;
}
.HomeDemo2 .info-wrapper .token-icon {
    left: 20px;
    width: 38px;
    height: 38px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
}
.HomeDemo2 .info-wrapper .token-descr {
    display: block;
    font-size: 16px;
    color: #fff;
    padding-left: 10px;
    font-weight: 300;
    line-height: 1.25;
}